import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGlobalIsLogoHidden, useGlobalIsLoading } from 'globalState';

const useHideLogoOnScroll = (scrollRef) => {
  const location = useLocation();
  const [isLoading] = useGlobalIsLoading();
  const [isLogoHidden, setIsLogoHidden] = useGlobalIsLogoHidden();
  const isHome = location.pathname === '/';

  useEffect(() => {
    const locoScroll = scrollRef.current;

    const onScroll = (args) => {
      const shouldHideLogo =
        args?.delta?.y > 0 && !isLoading && !isLogoHidden && !isHome;

      if (shouldHideLogo) {
        setIsLogoHidden(true);
      }
    };

    if (locoScroll) {
      locoScroll.on('scroll', onScroll);
    }

    // Clean-up
    return () => locoScroll?.off('scroll', onScroll);
  }, [isLoading, isHome, isLogoHidden, scrollRef, setIsLogoHidden]);

  useEffect(() => {
    return () => setIsLogoHidden(false);
  }, [setIsLogoHidden]);

  return scrollRef;
};

export default useHideLogoOnScroll;
