import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

import { useGlobalIsLoading } from 'globalState';
import useFetch from 'hooks/useFetch';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';
import ScrollContainer from 'components/ScrollContainer/ScrollContainer';

import styles from './About.module.scss';

const containerAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};

const textAnimation = {
  hidden: {
    y: 15,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.85,
      ease: 'easeOut',
    },
  },
};

const imageAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: 'easeOut',
    },
  },
};

const About = () => {
  const [, setGlobalIsLoading] = useGlobalIsLoading();

  const locoScrollRef = useRef();

  const [{ data: about, isLoading }, doFetch] = useFetch();

  const { email, phone, facebook, instagram, description, image } = about;
  const hasProject = !isLoading && !!description;

  // Load projects.
  useEffect(() => {
    doFetch({ endpoint: 'about' });
  }, [doFetch]);

  useEffect(() => {
    setGlobalIsLoading(isLoading);
  }, [isLoading, setGlobalIsLoading]);

  return (
    <TransitionWrapper>
      <Helmet>
        <title>About</title>
      </Helmet>

      <AnimatePresence>
        {hasProject && (
          <ScrollContainer ref={locoScrollRef} className={styles.scroll}>
            <motion.div
              className={styles.wrapper}
              variants={containerAnimation}
              initial="hidden"
              animate="show"
            >
              <div className={styles.contact} data-scroll>
                <motion.p className={styles.email} variants={textAnimation}>
                  <a href={`mailto:${email}`}>{email}</a>
                </motion.p>

                <motion.p className={styles.phone} variants={textAnimation}>
                  {phone}
                </motion.p>

                <ul className={styles.some}>
                  <motion.li className={styles.fb} variants={textAnimation}>
                    <a href={facebook} target="_blank" rel="noreferrer">
                      Facebook
                    </a>
                  </motion.li>
                  <motion.li
                    className={styles.instagram}
                    variants={textAnimation}
                  >
                    <a href={instagram} target="_blank" rel="noreferrer">
                      Instagram
                    </a>
                  </motion.li>
                </ul>
              </div>

              <motion.div
                className={styles.description}
                variants={textAnimation}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />

              <motion.img
                className={styles.image}
                variants={imageAnimation}
                src={image.sizes.large}
                alt={image.alt}
              />
            </motion.div>
          </ScrollContainer>
        )}
      </AnimatePresence>
    </TransitionWrapper>
  );
};

export default About;
