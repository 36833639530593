import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import useFetch from 'hooks/useFetch';

import styles from './CookieNotice.module.scss';

const cookieName = 'anneli-wikman-accepted-cookies';

const wrapperAnimation = {
  hidden: {
    y: 30,
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: 'easeOut',
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [{ data: cookieContent, isLoading }, doFetch] = useFetch();

  const { GA_TRACKING_ID } = cookieContent;

  const loadAnalytics = () => {
    if (GA_TRACKING_ID) {
      const scriptTag = document.createElement('script');

      scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;

      scriptTag.onload = () => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          // eslint-disable-next-line
          dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', `${GA_TRACKING_ID}`);
      };

      document.body.appendChild(scriptTag);
    }
  };

  const setCookie = (hasAccepted) => {
    const name = cookieName;
    const value = hasAccepted ? 'yes' : 'no';
    const days = 31;
    const date = new Date();

    // eslint-disable-next-line no-mixed-operators
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    const dateUTC = date.toUTCString();
    const expires = `; expires=${dateUTC}`;

    document.cookie = `${name}=${value}${expires}; path=/`;
  };

  const onClickAccept = (e) => {
    e.preventDefault();

    setCookie(true);
    setIsVisible(false);

    loadAnalytics();
  };

  const onClickDeny = (e) => {
    e.preventDefault();

    setCookie(false);
    setIsVisible(false);
  };

  // Load shop and items.
  useEffect(() => {
    doFetch({ endpoint: 'cookie-notice' });
  }, [doFetch]);

  useEffect(() => {
    if (!isLoading && !!GA_TRACKING_ID) {
      const hasCookie = !!document.cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName));

      setIsVisible(!hasCookie);
    }
  }, [isLoading, GA_TRACKING_ID]);

  if (!GA_TRACKING_ID) return null;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={styles.wrapper}
          variants={wrapperAnimation}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <div className={styles.block}>
            <p className={styles.text}>{cookieContent.text}</p>

            <div className={styles.buttons}>
              <button
                className={styles.button}
                type="button"
                onClick={onClickAccept}
              >
                {cookieContent.accept}
              </button>

              <p className={styles.or}>{cookieContent.or}</p>

              <button
                className={styles.button}
                type="button"
                onClick={onClickDeny}
              >
                {cookieContent.deny}
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(CookieNotice, () => true);
