// Get random number, between two values.
export const getRandomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const shuffleArray = (initialArray) => {
  const array = initialArray;

  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const isTouch =
  'ontouchstart' in window ||
  (window.DocumentTouch && document instanceof window.DocumentTouch);

export default {
  isTouch,
  shuffleArray,
  getRandomInteger,
};
