import { string, shape, number } from 'prop-types';
import { Link } from 'react-router-dom';

import RatioImage from 'components/RatioImage/RatioImage';

import styles from './ProjectCard.module.scss';

const ProjectCard = ({ slug, title, thumbnail }) => {
  return (
    <article className={styles.wrapper}>
      <Link className={styles.link} to={`/projects/${slug}`}>
        <span className={styles.imageWrapper}>
          <RatioImage
            className={styles.image}
            src={thumbnail.src}
            width={thumbnail.width}
            height={thumbnail.height}
          />
        </span>

        <span className={styles.title}>{title}</span>
      </Link>
    </article>
  );
};

ProjectCard.propTypes = {
  slug: string.isRequired,
  title: string.isRequired,
  thumbnail: shape({
    src: string,
    width: number,
    height: number,
  }),
};

ProjectCard.defaultProps = {
  thumbnail: {},
};

export default ProjectCard;
