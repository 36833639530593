import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Logo from 'components/Logo/Logo';
import Menu from 'components/Menu/Menu';
import ProjectList from 'components/ProjectList/ProjectList';
import ProjectPage from 'components/ProjectPage/ProjectPage';
import Shop from 'components/Shop/Shop';
import ShopPage from 'components/ShopPage/ShopPage';
import About from 'components/About/About';
import NoMatch from 'components/NoMatch/NoMatch';
import CookieNotice from 'components/CookieNotice/CookieNotice';

import styles from './App.module.scss';

const App = () => {
  const location = useLocation();

  return (
    <HelmetProvider>
      <div className={styles.wrapper}>
        <Helmet
          defaultTitle="Anneli Wikman"
          titleTemplate="%s - Anneli Wikman"
        />

        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact strict path="/" component={ProjectList} />
            <Route exact path="/projects/:slug?" component={ProjectPage} />
            <Route exact path="/shop" component={Shop} />
            <Route exact path="/shop/:slideIndex" component={ShopPage} />
            <Route exact path="/about" component={About} />

            <Route component={NoMatch} />
          </Switch>
        </AnimatePresence>

        <Logo />
        <Menu />
        <CookieNotice />
      </div>
    </HelmetProvider>
  );
};

export default App;
