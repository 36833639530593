import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useGlobalPageSubTitle } from 'globalState';

import styles from './Menu.module.scss';

const Menu = () => {
  const [pageSubTitle] = useGlobalPageSubTitle();

  return (
    <nav className={styles.wrapper}>
      <motion.div
        className={styles.content}
        initial={{ opacity: 0, y: 15, rotateX: -40 }}
        animate={{ y: 0, opacity: 1, rotateX: 0 }}
        transition={{ duration: 0.75, delay: 1 }}
      >
        <h1 className={styles.logo}>
          <Link to="/" className={styles.link}>
            Anneli Wikman
          </Link>
          <span className={styles.subTitle}>
            <AnimatePresence>
              {pageSubTitle.length > 0 && (
                <motion.span
                  key="page-sub-title"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {pageSubTitle}
                </motion.span>
              )}
            </AnimatePresence>
            &nbsp;
          </span>
        </h1>

        <ul className={styles.menu}>
          <li>
            <NavLink
              to="/shop"
              className={styles.link}
              activeClassName={styles.active}
            >
              Shop
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={styles.link}
              activeClassName={styles.active}
            >
              About
            </NavLink>
          </li>
        </ul>
      </motion.div>
    </nav>
  );
};

export default Menu;
