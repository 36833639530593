import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from 'utils/reportWebVitals';
import { isTouch } from 'utils/helpers';
import 'utils/inner-height-variable';

import App from 'components/App/App';

import 'styles/index.scss';

if (isTouch) {
  document.body.classList.add('has-touch');
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
