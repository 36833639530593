import React from 'react';
import { string, number } from 'prop-types';
import clsx from 'clsx';

import styles from './RatioImage.module.scss';

const RatioImage = ({ className, src, width, height, alt }) => {
  const ratio = height / width;

  return (
    <div
      className={clsx(className, styles.wrapper)}
      style={{ '--pt': `${ratio * 100}` }}
    >
      <div className={styles.image}>
        <img src={src} alt={alt} />
      </div>
    </div>
  );
};

RatioImage.propTypes = {
  src: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  className: string,
  alt: string,
};

RatioImage.defaultProps = {
  className: null,
  alt: null,
};

export default RatioImage;
