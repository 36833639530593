import { createGlobalState } from 'react-use';

// Page sub title.
export const useGlobalPageSubTitle = createGlobalState('');

// Loading.
export const useGlobalIsLoading = createGlobalState(true);

// Hidden logo.
export const useGlobalIsLogoHidden = createGlobalState(false);

export default {
  useGlobalIsLoading,
  useGlobalPageSubTitle,
};
