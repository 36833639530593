import { useEffect, useRef, useState } from 'react';
import Masonry from 'react-masonry-css';
import { motion, AnimatePresence } from 'framer-motion';
import OnImagesLoaded from 'react-on-images-loaded';
import { useLockBodyScroll } from 'react-use';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useGlobalIsLoading } from 'globalState';
import useFetch from 'hooks/useFetch';
import useIsMobile from 'hooks/useIsMobile';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';
import ScrollContainer from 'components/ScrollContainer/ScrollContainer';
import RatioImage from 'components/RatioImage/RatioImage';

import styles from './Shop.module.scss';

const textAnimation = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.85,
      ease: 'easeOut',
    },
  },
};

const revealAnimation = {
  hidden: {
    y: 45,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.85,
      delay: 0.4,
      ease: 'easeOut',
    },
  },
};

const Shop = () => {
  const [, setGlobalIsLoading] = useGlobalIsLoading();

  const wrapperRef = useRef();
  const locoScrollRef = useRef();
  const [hasLoadedImages, setHasLoadedImages] = useState(false);
  const [{ data: shop, isLoading }, doFetch] = useFetch();
  const isMobile = useIsMobile();

  const { leftCol, rightCol, images } = shop;

  const hasImages = !isLoading && images && images.length > 0;
  const isReady = !isLoading && hasLoadedImages;

  const onImagesLoaded = () => setHasLoadedImages(true);

  // Lock if not ready.
  useLockBodyScroll(!isReady);

  // Load shop and items.
  useEffect(() => {
    doFetch({ endpoint: 'shop' });
  }, [doFetch]);

  // Global loading.
  useEffect(() => {
    setGlobalIsLoading(!isReady);
  }, [isReady, setGlobalIsLoading]);

  if (!hasImages) return null;

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Shop</title>
      </Helmet>

      <AnimatePresence>
        <ScrollContainer className={styles.wrapper} ref={locoScrollRef}>
          {!isMobile && (
            <>
              <div className={styles.leftCol} data-scroll-section>
                <motion.p
                  variants={textAnimation}
                  animate={isReady ? 'show' : 'hidden'}
                  transition={{ duration: 0.5 }}
                >
                  {leftCol}
                </motion.p>
              </div>

              <div className={styles.rightCol} data-scroll-section>
                <motion.p
                  variants={textAnimation}
                  animate={isReady ? 'show' : 'hidden'}
                  transition={{ duration: 0.5 }}
                >
                  {rightCol}
                </motion.p>
              </div>
            </>
          )}

          <div className={styles.spacer} data-scroll-section />
          <div ref={wrapperRef} data-scroll-section>
            {isMobile && (
              <motion.div
                className={styles.textMobile}
                variants={textAnimation}
                animate={isReady ? 'show' : 'hidden'}
                data-scroll
              >
                <p>
                  {leftCol}
                  <br />
                  &nbsp;
                  <br />
                  {rightCol}
                </p>
              </motion.div>
            )}

            <OnImagesLoaded
              onLoaded={onImagesLoaded}
              onTimeout={onImagesLoaded}
              timeout={7000}
            >
              <motion.div
                className={styles.images}
                variants={revealAnimation}
                animate={isReady ? 'visible' : 'hidden'}
              >
                <div data-scroll>
                  <Masonry
                    className={styles.grid}
                    columnClassName={styles.imagesColumn}
                    breakpointCols={isMobile ? 2 : 3}
                  >
                    {images.map(
                      (image, index) =>
                        image && (
                          <Link
                            key={`image-${image.id}`}
                            className={styles.link}
                            to={`/shop/${index}`}
                            style={{ maxWidth: image.sizes['large-width'] }}
                            data-title={image.title}
                          >
                            <RatioImage
                              className={styles.image}
                              {...{
                                width: image.width,
                                height: image.height,
                                src: image.sizes.medium,
                                alt: image.alt,
                              }}
                            />
                          </Link>
                        )
                    )}
                  </Masonry>
                </div>
              </motion.div>
            </OnImagesLoaded>
          </div>
        </ScrollContainer>
      </AnimatePresence>
    </TransitionWrapper>
  );
};

export default Shop;
