import React, { useCallback, useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import OnImagesLoaded from 'react-on-images-loaded';
import { useLockBodyScroll } from 'react-use';

import { useGlobalIsLoading } from 'globalState';
import { getRandomInteger } from 'utils/helpers';
import useFetch from 'hooks/useFetch';
import useIsMobile from 'hooks/useIsMobile';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';
import ScrollContainer from 'components/ScrollContainer/ScrollContainer';
import ProjectCard from 'components/ProjectCard/ProjectCard';

import styles from './ProjectList.module.scss';

// Get offset for an element from top top.
const getOffsetTop = (element) =>
  window.scrollY + element.getBoundingClientRect().top;

const ProjectList = () => {
  const [, setGlobalIsLoading] = useGlobalIsLoading();

  const listRef = useRef();
  const locoScrollRef = useRef();

  const [hasLoadedImages, setHasLoadedImages] = useState(false);

  const [{ data: projects, isLoading }, doFetch] = useFetch();

  const isMobile = useIsMobile();

  const isReady = !isLoading && hasLoadedImages;
  const hasProjects = !isLoading && projects && projects.length > 0;

  // Render project.
  const renderProject = ({ id, slug, title, thumbnail }) => (
    <div key={`project-${id}`} className={styles.item}>
      <div
        className={styles.inner}
        data-scroll
        data-scroll-speed={getRandomInteger(2, 4)}
        data-scroll-delay={0.3}
      >
        <ProjectCard
          {...{
            slug,
            title,
            thumbnail,
          }}
        />
      </div>
    </div>
  );

  // Render projects.
  const renderProjects = useCallback(
    (isEven = false) =>
      projects.map(
        (project, index) =>
          (isEven ? index % 2 === 0 : index % 2 !== 0) && renderProject(project)
      ),
    [projects]
  );

  const onImagesLoaded = () => setHasLoadedImages(true);

  // Lock if not ready.
  useLockBodyScroll(!isReady);

  // Intro animation.
  useEffect(() => {
    if (isReady) {
      const { innerHeight } = window;
      const wrapper = listRef.current;
      const items = [...wrapper.querySelectorAll(`.${styles.item}`)];
      const visibleItems = items.filter(
        (item) => getOffsetTop(item) < innerHeight
      );

      const timeline = gsap.timeline();

      // Update scroller.
      locoScrollRef.current.update();

      items.forEach((item) => gsap.set(item, { opacity: 1 }));

      visibleItems.forEach((item, index) => {
        timeline.fromTo(
          item,
          {
            y: 30,
            opacity: 0,
            duration: 0.75,
          },
          {
            y: 0,
            opacity: 1,
            ease: 'easeIn',
            delay: 0.25 * index,
          },
          0
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  // Load projects.
  useEffect(() => {
    doFetch({ endpoint: 'projects' });
  }, [doFetch]);

  // Global loading.
  useEffect(() => {
    setGlobalIsLoading(!isReady);
  }, [isReady, setGlobalIsLoading]);

  return (
    <TransitionWrapper>
      <ScrollContainer ref={locoScrollRef} className={styles.wrapper}>
        {hasProjects && (
          <OnImagesLoaded
            onLoaded={onImagesLoaded}
            onTimeout={onImagesLoaded}
            timeout={7000}
          >
            <div className={styles.content}>
              <div ref={listRef} className={styles.list}>
                {isMobile && (
                  <div className={styles.listMobile} data-scroll-section>
                    {renderProjects(true)}
                    {renderProjects()}
                  </div>
                )}

                {!isMobile && (
                  <>
                    <div className={styles.leftCol} data-scroll-section>
                      {renderProjects(true)}
                    </div>
                    <div className={styles.rightCol} data-scroll-section>
                      {renderProjects()}
                    </div>
                  </>
                )}
              </div>
            </div>
          </OnImagesLoaded>
        )}
      </ScrollContainer>
    </TransitionWrapper>
  );
};

export default ProjectList;
