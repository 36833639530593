import React, { forwardRef, useEffect, useRef } from 'react';
import { node, string } from 'prop-types';
import LocomotiveScroll from 'locomotive-scroll';

import useHideLogoOnScroll from 'hooks/useHideLogoOnScroll';

const ScrollContainer = forwardRef(({ children, className }, scrollRef) => {
  const wrapperRef = useRef();

  useHideLogoOnScroll(scrollRef);

  // Init locoScroll.
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    scrollRef.current = new LocomotiveScroll({
      el: wrapperRef.current,
      smooth: true,
    });

    // Clean-up
    return () => scrollRef.current?.destroy();
  }, [scrollRef]);

  return (
    <div className={className} ref={wrapperRef} data-scroll-container>
      {children && children}
    </div>
  );
});

ScrollContainer.propTypes = {
  children: node.isRequired,
  className: string,
};

ScrollContainer.defaultProps = {
  className: null,
};

export default ScrollContainer;
