import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import LocomotiveScroll from 'locomotive-scroll';
import OnImagesLoaded from 'react-on-images-loaded';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLockBodyScroll } from 'react-use';
import { Helmet } from 'react-helmet-async';

import { useGlobalPageSubTitle, useGlobalIsLoading } from 'globalState';
import useFetch from 'hooks/useFetch';
import useIsMobile from 'hooks/useIsMobile';
import useHideLogoOnScroll from 'hooks/useHideLogoOnScroll';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';

import styles from './ProjectPage.module.scss';

gsap.registerPlugin(ScrollTrigger);

const listAnimation = {
  hidden: {
    y: '30px',
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.25,
      duration: 0.75,
      ease: 'easeOut',
    },
  },
};

const ProjectPage = () => {
  const [, setGlobalIsLoading] = useGlobalIsLoading();
  const [, setPageSubTitle] = useGlobalPageSubTitle();

  const wrapperRef = useRef();
  const scrollRef = useRef();
  const [hasLoadedImages, setHasLoadedImages] = useState(false);
  const { slug } = useParams();
  const [{ data: project, isLoading }, doFetch] = useFetch();
  const isMobile = useIsMobile();

  const { title, description, images } = project;
  const isReady = !isLoading && hasLoadedImages;
  const hasProject = !isLoading && images && images.length > 0;

  const onImagesLoaded = () => setHasLoadedImages(true);

  // Lock if not ready.
  useLockBodyScroll(!isReady);

  useHideLogoOnScroll(scrollRef);

  // Load projects.
  useEffect(() => {
    doFetch({ endpoint: `projects/${slug}` });
  }, [slug, doFetch]);

  // Set page title.
  useEffect(() => {
    if (title) {
      setPageSubTitle(title);
    }

    return function cleanup() {
      setPageSubTitle('');
    };
  }, [setPageSubTitle, title]);

  useEffect(() => {
    const updateLocoScroll = () => scrollRef?.current.update();

    const locoScroll = new LocomotiveScroll({
      el: wrapperRef.current,
      smooth: true,
    });

    const blocks = wrapperRef?.current?.querySelectorAll(`.${styles.block}`);

    locoScroll.on('scroll', ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(wrapperRef.current, {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: wrapperRef.current.style.transform ? 'transform' : 'fixed',
    });

    blocks.forEach((block, index) => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          id: 'trigger1',
          trigger: block,
          scroller: wrapperRef.current,
          scrub: true,
          end: '+=200%',
        },
      });

      const isFirst = index === 0;

      timeline.set(block, {
        opacity: isFirst ? 1 : 0.1,
        scale: isMobile ? 1 : 0.95,
      });

      timeline.fromTo(
        block,
        {
          opacity: isFirst ? 1 : 0.1,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );

      timeline.to(block, {
        opacity: isMobile ? 0 : 0.1,
        scale: 1,
      });
    });

    scrollRef.current = locoScroll;

    ScrollTrigger.addEventListener('refresh', updateLocoScroll);

    return () => {
      ScrollTrigger?.removeEventListener('refresh', updateLocoScroll);
      ScrollTrigger?.getById('trigger1')?.kill();
      scrollRef?.current?.destroy();
    };
  }, [isMobile, isReady]);

  // Global loading.
  useEffect(() => {
    setGlobalIsLoading(!isReady);
  }, [isReady, setGlobalIsLoading]);

  return (
    <TransitionWrapper>
      <Helmet>
        <title>{hasProject ? title : null}</title>
      </Helmet>

      <div ref={wrapperRef} data-scroll-container>
        <AnimatePresence>
          {hasProject && (
            <motion.section
              key="project-page-image-list"
              className={styles.wrapper}
              variants={listAnimation}
              initial="hidden"
              animate="show"
            >
              <div className={styles.spacer} data-scroll-section />
              <div className={styles.images} data-scroll-section>
                <OnImagesLoaded
                  onLoaded={onImagesLoaded}
                  onTimeout={onImagesLoaded}
                  timeout={7000}
                >
                  {images.map((image, index) => {
                    const isLandscape = image
                      ? image.height < image.width
                      : false;

                    return (
                      image && (
                        <React.Fragment key={`image-${image.id}`}>
                          <div
                            className={clsx(styles.block, {
                              [styles.landscape]: isLandscape,
                              [styles.portrait]: !isLandscape,
                            })}
                          >
                            <img
                              src={image.sizes.large}
                              alt={image.alt}
                              crossOrigin=""
                              data-sampler="planeTexture"
                              data-scroll
                            />
                          </div>
                          {index === 0 && description && (
                            <p
                              className={styles.description}
                              data-scroll
                              data-scroll-speed={1.5}
                            >
                              {description}
                            </p>
                          )}
                        </React.Fragment>
                      )
                    );
                  })}
                </OnImagesLoaded>
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    </TransitionWrapper>
  );
};

export default ProjectPage;
