import { useEffect, useRef, useState, useReducer } from 'react';

const isDevelopment = process.env.NODE_ENV === 'development';
const domain = isDevelopment ? 'http://anneliwikman.test' : '';
const apiUrl = `${domain}/wp-json/api/v1`;

const useFetch = () => {
  const cache = useRef({});
  const [query, setQuery] = useState(null);

  const initialState = {
    status: 'idle',
    isLoading: false,
    error: null,
    data: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching', isLoading: true };
      case 'FETCHED':
        return {
          ...initialState,
          status: 'fetched',
          data: action.payload,
        };
      case 'FETCH_ERROR':
        return {
          ...initialState,
          status: 'error',
          error: action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let cancelRequest = false;

    const fetchData = async () => {
      const { endpoint } = query;

      dispatch({ type: 'FETCHING' });

      // Cached.
      if (cache.current[endpoint]) {
        const data = cache.current[endpoint];

        dispatch({ type: 'FETCHED', payload: data });
      } else {
        try {
          const response = await fetch(`${apiUrl}/${endpoint}`);
          const data = await response.json();

          cache.current[endpoint] = data;

          if (cancelRequest) return;

          dispatch({ type: 'FETCHED', payload: data });
        } catch (error) {
          if (cancelRequest) return;

          dispatch({ type: 'FETCH_ERROR', payload: error.message });
        }
      }
    };

    if (query) {
      fetchData();
    }

    return function cleanup() {
      cancelRequest = true;
    };
  }, [query]);

  return [state, setQuery];
};

export default useFetch;
