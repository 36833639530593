import React, { useEffect, useRef, useState } from 'react';
import { useLockBodyScroll, useUpdateEffect } from 'react-use';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import Swiper, { Navigation, Lazy, Parallax } from 'swiper';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';

import { useGlobalIsLoading, useGlobalIsLogoHidden } from 'globalState';
import useFetch from 'hooks/useFetch';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';

import styles from './ShopPage.module.scss';

Swiper.use([Navigation, Lazy, Parallax]);

const revealAnimation = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

const ShopPage = () => {
  const [, setGlobalIsLoading] = useGlobalIsLoading();
  const [, setGlobalIsLogoHidden] = useGlobalIsLogoHidden();

  const { slideIndex } = useParams();
  const wrapperRef = useRef(null);
  const swiperRef = useRef(null);
  const firstImageRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const [hasLoadedImages, setHasLoadedImages] = useState(false);
  const [{ data: shop, isLoading }, doFetch] = useFetch();

  const initialSlide = parseInt(slideIndex, 10) ?? 0;
  const { images } = shop;

  const hasImages = !isLoading && images && images.length > 0;
  const isReady = !isLoading && hasImages && hasLoadedImages;

  // Lock if not ready.
  useLockBodyScroll(!isReady);

  // Load shop and items.
  useEffect(() => {
    doFetch({ endpoint: 'shop' });
  }, [doFetch]);

  // Global loading.
  useEffect(() => {
    setGlobalIsLoading(!isReady);
  }, [isReady, setGlobalIsLoading]);

  useEffect(() => {
    let swiper = null;

    const onLoad = () => {
      setHasLoadedImages(true);
      swiper.update();
    };

    if (hasImages) {
      swiper = new Swiper(wrapperRef.current, {
        initialSlide,
        speed: 1000,
        loop: true,
        spaceBetween: 60,
        slidesPerView: 1,
        centeredSlides: true,
        slideToClickedSlide: true,
        observer: true,
        observeParents: true,
        parallax: true,
        touchStartPreventDefault: false,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          elementClass: styles.isLazy,
        },
        wrapperClass: styles.swiperWrapper,
        slideClass: styles.swiperSlide,
        slideActiveClass: styles.active,
        navigation: {
          nextEl: nextButtonRef.current,
          prevEl: prevButtonRef.current,
        },
      });

      swiperRef.current = swiper;

      if (firstImageRef?.current) {
        firstImageRef.current.onload = onLoad();
      }
    }

    return () => {
      swiper?.destroy();
    };
  }, [hasImages, initialSlide]);

  useUpdateEffect(() => {
    setGlobalIsLogoHidden(isReady);

    return () => {
      setGlobalIsLogoHidden(false);
    };
  }, [isReady]);

  return (
    <TransitionWrapper>
      <Helmet>
        <title>Shop</title>
      </Helmet>

      {hasImages && (
        <motion.div
          className={styles.wrapper}
          variants={revealAnimation}
          animate={isReady ? 'visible' : 'hidden'}
        >
          <div ref={wrapperRef} className={styles.swiperContainer}>
            <div className={styles.swiperWrapper}>
              {images.map((image, index) => {
                if (!image) return null;

                const isLandscape =
                  image.sizes['large-height'] > image.sizes['large-width'];
                const isActiveImage = initialSlide === index;

                const emailSubject = `Interested in print: ${image.title}`;

                return (
                  <div
                    key={`modal-image-${image.id}`}
                    className={clsx(styles.swiperSlide, {
                      [styles.landscape]: isLandscape,
                      [styles.portrait]: !isLandscape,
                    })}
                  >
                    <div
                      className={styles.image}
                      data-swiper-parallax-opacity="0"
                      data-swiper-parallax-scale="0.9"
                    >
                      <img
                        ref={isActiveImage ? firstImageRef : null}
                        className={styles.isLazy}
                        data-src={image.sizes.large}
                        alt={image.alt}
                      />
                    </div>

                    <div
                      className={clsx(styles.caption, {
                        [styles.initial]: slideIndex,
                      })}
                      data-swiper-parallax-opacity="0"
                    >
                      {image.caption && <p>{image.caption}</p>}
                      <p>
                        If you’re interested in this print please email&nbsp;
                        <a
                          href={`mailto:anneli@anneliwikman.se?subject=${emailSubject}`}
                        >
                          anneli@anneliwikman.se
                        </a>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <button
            ref={prevButtonRef}
            type="button"
            className={clsx(styles.prevButton, {
              [styles.isReady]: isReady,
            })}
          >
            <ArrowIcon className={styles.icon} />
          </button>
          <button
            ref={nextButtonRef}
            type="button"
            className={clsx(styles.nextButton, {
              [styles.isReady]: isReady,
            })}
          >
            <ArrowIcon className={styles.icon} />
          </button>
        </motion.div>
      )}
    </TransitionWrapper>
  );
};

export default React.memo(ShopPage, () => true);
