import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import DrawSVGPlugin from 'utils/gsap/DrawSVGPlugin';

import { useGlobalIsLoading, useGlobalIsLogoHidden } from 'globalState';
import useIsMobile from 'hooks/useIsMobile';

import styles from './Logo.module.scss';

gsap.registerPlugin(DrawSVGPlugin);

const Logo = () => {
  const [isLoading] = useGlobalIsLoading();
  const [isLogoHidden] = useGlobalIsLogoHidden();

  const tlRef = useRef(null);
  const wrapperRef = useRef(null);
  const circleRef = useRef(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    const tl = gsap.timeline({
      paused: false,
      repeat: -1,
    });
    const circleDuration = 1;

    tl.fromTo(
      circleRef.current,
      { drawSVG: '0% 100%' },
      { duration: circleDuration, drawSVG: '100% 100%', ease: 'power2.out' },
      `-=${circleDuration * 0.2}`
    );

    tl.fromTo(
      circleRef.current,
      { drawSVG: '0% 0%' },
      {
        duration: circleDuration * 1.5,
        drawSVG: '120% 100%',
        ease: 'power2.out',
      },
      `-=${circleDuration * 0.3}`
    );

    tl.fromTo(
      circleRef.current,
      { drawSVG: '0% 0%' },
      { duration: circleDuration, drawSVG: '0% 100%', ease: 'power2.out' },
      `-=${circleDuration * 0.25}`
    );

    tlRef.current = tl;

    // Clean up.
    return () => tl?.kill();
  }, []);

  useEffect(() => {
    const tl = tlRef.current;

    if (!isLoading) {
      const duration = tl.duration() - tl.time();

      tl.pause();

      if (duration > 0) {
        gsap.to(tl, {
          duration,
          progress: 1,
        });

        if (isMobile) {
          gsap.to(wrapperRef.current, {
            opacity: 0,
            delay: duration - 0.3,
          });
        }
      }
    } else if (isLoading) {
      gsap.to(wrapperRef.current, { opacity: 1 });

      tl.play();
    }

    // Clean up.
    return () => tl?.kill();
  }, [isMobile, isLoading]);

  return (
    <motion.div
      ref={wrapperRef}
      className={clsx(styles.wrapper, {
        [styles.hidden]: isLogoHidden,
      })}
      initial={{ opacity: 0, y: 15 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.1 166.1">
        <ellipse cx="60.5" cy="83.1" rx="60.5" ry="83.1" fill="#fff" />
        <path
          d="M82.3,87.4s.3,25.1-14.7,42.3C52.7,112.5,52.9,87.4,52.9,87.4"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.4"
        />
        <path
          d="M67.2,87.4s.3,25.1-14.7,42.3C37.6,112.5,37.8,87.4,37.8,87.4"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.4"
        />
        <line
          x1="48.3"
          y1="59.4"
          x2="72.9"
          y2="59.4"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.4"
        />
        <path
          d="M45.9,77.1S45.6,52,60.5,34.7C75.5,52,75.2,77.1,75.2,77.1"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.4"
        />
        <ellipse
          ref={circleRef}
          cx="60.5"
          cy="83.2"
          rx="42.5"
          ry="64.7"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.4"
        />
      </svg>
    </motion.div>
  );
};

export default Logo;
