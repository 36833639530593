import React from 'react';

import TransitionWrapper from 'components/TransitionWrapper/TransitionWrapper';

import styles from './NoMatch.module.scss';

const NoMatch = () => {
  return (
    <TransitionWrapper>
      <section className={styles.wrapper}>404</section>
    </TransitionWrapper>
  );
};

export default NoMatch;
